import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AboutMe } from "./pages/AboutMe";
import { Homepage } from "./pages/Homepage";
import { Projects } from "./pages/Projects";
import { Student } from "./pages/Student";
import { Architecture } from "./pages/Architecture";
import { Interior } from "./pages/Interior";
import { Creative } from "./pages/Creative";

import AppContent from "./components/appContent/AppContent";

import { LanguageProvider } from "./contexts/Language";

import "./manifest.json";
import "./favicon.ico";
import "./logo192.png";

import { ProjectsProvider } from "./contexts/Projects";
import { SingleProjectView } from "./components/projects-main/SingleProjectView";

import "./App.css";

function App() {
  return (
    <LanguageProvider>
      <ProjectsProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <div className="App">
                  <AppContent></AppContent>
                </div>
              }
            >
              <Route path="/" element={<Homepage />} />
              <Route path="/project" element={<SingleProjectView />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/studentprojects" element={<Student />} />
              <Route path="/projects/architecture" element={<Architecture />} />
              <Route path="/projects/interior" element={<Interior />} />
              <Route path="/projects/creative" element={<Creative />} />
              <Route path="/about-me" element={<AboutMe />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ProjectsProvider>
    </LanguageProvider>
  );
}

export default App;
