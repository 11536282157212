export const getImageSrc = (imageName) =>
  `${process.env.PUBLIC_URL}/${imageName}`;

export const capitalizeWord = (word) => {
  const firstLetter = word.split("")[0];

  return `${firstLetter.toUpperCase()}${word.substring(1, word.length)}`;
};

export function randomSamples(arr, count) {
  const arraySize = arr.length;

  const sampleSize = Math.min(count, arraySize);

  const sampleIndexes = [];

  for (var i = 0; i < sampleSize; i++) {
    var nextIndex = Math.floor(Math.random() * arraySize);

    while (sampleIndexes.includes(nextIndex)) {
      nextIndex = Math.floor(Math.random() * arraySize);
    }

    sampleIndexes.push(nextIndex);
  }

  return sampleIndexes.map((i) => arr[i]);
}
