export const addProject = (dispatch, newProject) =>
  dispatch({
    type: "addProject",
    payload: {
      newProject,
    },
  });

export const setCurrentProject = (dispatch, project) =>
  dispatch({ type: "setCurrentProject", payload: { project } });
