import React from "react";

import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import { t } from "../../../contexts/Language/reducer";

import { capitalizeWord } from "../../../util/Functions";

const ProjectCategoryOverlay = (props) => {
  const { onItemSelect, styles, languageState } = props;

  const navigate = useNavigate();

  const goTo = (path) => {
    onItemSelect();
    navigate(`/projects/${path}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Button variant="outline" onClick={() => goTo("interior")}>
        <Typography sx={{ ...styles.typography }}>
          {`${capitalizeWord(t("interiordesign", languageState))} `}
        </Typography>
      </Button>

      <Button variant="outline" onClick={() => goTo("studentprojects")}>
        <Typography sx={{ ...styles.typography }}>
          {capitalizeWord(t("studentprojects", languageState))}
        </Typography>
      </Button>

      <Button sx={{ paddingTop: "2%" }} disabled={true} variant="outline">
        <Typography sx={{ ...styles.typography, fontSize: "18px" }}>
          {t("workinprogress", languageState)}
        </Typography>
      </Button>
      <Button
        disabled={true}
        variant="outline"
        onClick={() => goTo("architecture")}
      >
        <Typography sx={{ ...styles.typography }}>
          {`${capitalizeWord(t("architecture", languageState))} `}
        </Typography>
      </Button>
      <Button
        disabled={true}
        variant="outline"
        onClick={() => goTo("creative")}
      >
        <Typography sx={{ ...styles.typography }}>
          {`${capitalizeWord(t("creative", languageState))} `}
        </Typography>
      </Button>
    </Box>
  );
};

export default ProjectCategoryOverlay;
