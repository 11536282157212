import * as React from "react";
import { Squash as Hamburger } from "hamburger-react";

import { IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { LanguageContext } from "../../contexts/Language";
import { setEnglish, setMacedonian } from "../../contexts/Language/actions";

import { MEDIA_QUERY_MAX_WIDTH } from "../../util/Constants";

import "./TopNavigationBar.css";

const AppBar = styled(
  MuiAppBar,
  {}
)(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const TopNavigationBar = (props) => {
  const {
    onHamburgerClick,
    backArrowVisible,
    goBackCallback,
    isHamburgerClosed,
  } = props;

  const [languageState, languageDispatch] = React.useContext(LanguageContext);

  const MAX_WIDTH_QUERY = `(max-width: ${MEDIA_QUERY_MAX_WIDTH}px`;
  const [matches, setMatches] = React.useState(
    window.matchMedia(MAX_WIDTH_QUERY).matches
  );

  React.useEffect(
    () =>
      window
        .matchMedia(MAX_WIDTH_QUERY)
        .addEventListener("change", (e) => setMatches(e.matches)),
    []
  );

  const currentLanguage = languageState.language;
  const selectMacedonianLanguage = () => setMacedonian(languageDispatch);
  const selectEnglishLanguage = () => setEnglish(languageDispatch);

  const isEnglish = currentLanguage === "en";
  const isMacedonian = currentLanguage === "mk";

  const languageSelectButtonsStyleBase = (languageCheck) => ({
    textDecoration: languageCheck ? "underline" : null,
    fontSize: matches ? "10px" : "16px",
  });

  return (
    <Box sx={{ display: "flex", color: "transparent" }}>
      <CssBaseline />

      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            {backArrowVisible ? (
              <IconButton color="inherit" onClick={goBackCallback}>
                <ArrowBackIosIcon></ArrowBackIosIcon>
              </IconButton>
            ) : null}
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>
            <Box
              sx={{
                paddingRight: "2%",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <Box
                onClick={selectEnglishLanguage}
                color="black"
                sx={{
                  ...languageSelectButtonsStyleBase(isEnglish),
                  paddingRight: "5px",
                }}
              >
                en
              </Box>
              |
              <Box
                onClick={selectMacedonianLanguage}
                color="black"
                sx={{
                  ...languageSelectButtonsStyleBase(isMacedonian),
                  paddingLeft: "5px",
                  paddingRight: "20px",
                }}
              >
                мк
              </Box>
            </Box>
            <Hamburger
              toggled={!isHamburgerClosed}
              onToggle={onHamburgerClick}
            />
          </Toolbar>
        </AppBar>
      </Box>
    </Box>
  );
};
// });

export default TopNavigationBar;
