import { Box, Typography } from "@mui/material";
import useImage from "./useImage";

const Image = ({ filePath, alt, className, ...rest }) => {
  const { loading, error, image } = useImage(filePath);

  if (error) return <Typography>{alt}</Typography>;

  return (
    <Box sx={{ overflow: "hidden" }}>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <img
          style={{
            objectFit: "scale-down",
            maxWidth: "100%",
            // borderRadius: 100,
          }}
          src={image}
          alt={alt}
          {...rest}
        />
      )}
    </Box>
  );
};

export default Image;
