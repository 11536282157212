const language = {
  test1: "value1",
  test2: "value2",
  extraTranslation: "extraTranslation",
  projects: "projects",
  "about me": "about me",
  contact: "contact",
  "My projects": "My projects",
  "Show all": "Show all",
  Partner: "Partner",

  "see-project": "See project",

  experience: "professional experience",
  december: "december",
  april: "april",
  parametar: "parametar - Skopje",

  education: "education",
  arh: "Faculty of Architecture",
  ukim: "Ss. Cyril and Methodius University",
  highschool: 'High school "Jane Sandanski" - Strumica',
  software: "software",
  Architect: "Architect",
  MarijaStoilova: "Marija Stoilova",
  email: "e-mail",

  homepage: "homepage",
  studentprojects: "student projects",
  architecture: "architecture",
  interiordesign: "interior design",
  creative: "creative",

  workinprogress: "work in progress",

  // project defs

  // INTERIOR DESIGN

  "project-irene-apartment": "Sculptor's Sanctum",
  "project-irene-apartment-commissioned-for": "Bitola, N. Macedonia",
  "project-irene-apartment-one-liner":
    "One bedroom apartment for a young sculptor",

  // first
  "project-highschool-title": "High school - Krushevo",
  "project-highschool-commissioned-for": "Studio V - 2017",
  "project-highschool-one-liner":
    "This is a student project for a tourism and hospitality high school",
  "project-highschool-description-1":
    "This is a student project for a tourism and hospitality high school.",
  "project-highschool-description-2":
    "The program is developed horizontally and is made up of multiple inter-connected units, meshed with atrium space.",
  "project-highschool-description-3":
    "Despite the significant surface over which the object develops, the game of volumes, the accentuation of the horizontal axis and the tall greenery\
    that surrounds the object allow it to remain subtle in the environment.",

  // second
  "project-domus-title": "Domus",
  "project-domus-commissioned-for": "Studio VI - 2018",
  "project-domus-partner": "Violeta Jovanova",
  "project-domus-one-liner":
    "Domus is an ancient Roman atrium dwelling, where life is centered around a hal with an open skylight.",
  "project-domus-description-1":
    "Domus is an ancient Roman atrium dwelling, where life is centered around a hal with an open skylight. \
    In a classical layout of a roman domus, the atrium represented a focal point of the entire house plan. ",
  "project-domus-description-2":
    "Being the main room in the public part of the house, the atrium was the center of all family, politic and social activities in the household. \
    This type of a house served as an inspiration for this project, which foresees an array of urban housing connected with stairwells between each other. \
    Every house in the array is a domus, an introverted housing type oriented towards an interior yard with a tree -- this tree being The Guardian of the home. ",
  "project-domus-description-3":
    "The living space is on the ground level, placed around the interior yard and connected in a circular, seamless fashion, \
    where the passages between rooms are subtle and fluent.",

  // third
  "project-botanicka-title": "Botanical garden",
  "project-botanicka-commissioned-for": "Studio VIII - 2019",
  "project-botanicka-partner": "Simona Galazova",
  "project-botanicka-one-liner":
    "Creating subtle, ortogonal pathways as an extension of nature.",
  "project-botanicka-description-1":
    'The Botanical garden at the Faculty of Natural Sciences and Mathematics in Skopje is located at a bordering between the Alexander The Great boulevard, \
      the Chemical Institute, Gazi Baba municipality and the "Stiv Naumov" dormitory.\
      Here, we traced ortogonal pathways, so that the objects only take a part of the garden, \
      allowing for free movement of people that pass here daily.',
  "project-botanicka-description-2":
    "The orthogonal pathways that lead through the garden in addition have ortogonally-positioned glass cubes with dimensions \
      MX3M и MX2M (М=6м),with sufficient space left in height for the plants which are placed here.\
      These cubes are connected underground and are regulated to allow for ideal conditions for the plants.",
  "project-botanicka-description-3":
    'The forest of trees on the outside is mimicked in the interior of the garden. \
    The glass cubes are carried by columns which also carry the facade, and "trees" - umbrella-like shaped system \
    made of a circular steel column and circular, sloping steel profiles which form the canopy of the tree and carry the roof.',

  // fourth
  "project-banja-title": "Banja Bansko",
  "project-banja-commissioned-for": "Master thesis - 2021",
  "project-banja-one-liner":
    "Integrating nature and architecture in a harmonic entity.",
  "project-banja-description-1":
    "This study is an attempt at integrating nature and architecture, so that they can function as an harmonic entity \
  all while reanimating the historical spirit of public baths in an contemporary form.",
  "project-banja-description-2":
    "The chosen location is at the edge of the village of Bansko, in an archeological zone. \
  It is a meeting place separating three different areas - a field, a forest and an archelogical/architectural zone. \
  Joined together, they affect the location in such a way that a megastructure can be created -- a programatical and spatial entity, \
  that is a factor in uniting the plan and creating an immutable body.",
  "project-banja-description-3":
    "The structure lies just below the thick forest of Belasica.\
  Due to its position, the object foresees cavities and empty spaces inside, just like the field surrounding it, will be filled with both tall and low foliage, \
  thus creating a flowing effect.\
  Using the idea of 'field conditions' of Stan Allen, the object is cast in a field, and the elements inside intertwine between each other.",
  "project-banja-description-4":
    "The complex itself is a forest of wooden and steel columns in the interior and a thicket of wooden tree stems on the exterior. \
  The object is composed of a horeca section and a main baths section. \
  Each of the baths has a distinct character, function and ambient.\
  By changing the materials, together with the presense of gardens intertwined with empty spaces, the structure defines its identity.\
  By adding a roman element - arc - we use symbolism to remind the visitor that this bath traces its roots back to the ancient roman bath, yet still retaining its own identity and character.",

  // fifth
  "project-skolo2020-title": "Park in a school",
  "project-skolo2020-commissioned-for": "Studio IX - 2020",
  "project-skolo2020-one-liner":
    "Individual, yet ensembled buildings of similar character.",
  "project-skolo2020-description-1":
    "The topic park in a school includes a kindergarden and elementary school with an existing gymnasium as two separate objects.\
  The location is in close proximity to the city park, in a relatively quiet neighbourhood.\
  The formative process pertains to two references,\
  one being the integration in the surrounding environment, and the other being the concept of letting nature creep into objects.",
  "project-skolo2020-description-2":
    "The kindergarden is organized in two tracts between which lies a linear atrium, visible from all parts of the kindergarden.\
  The linear atrium has small extensions, each of which supports a different activity for the children.\
  On the floor above are the primary rooms of the kindergarden, each of which has its own terrace.\
  Done like this, the extensions have a extendable glass system which can be used to shelter the children and allow for play to continue, even when the conditions outside are not pleasant.\
  The remainder of the plot is surfaced with soft materials and greenery.",
  "project-skolo2020-description-3":
    "The school functions in a similar way.\
  The object is split in the middle and has a park flowing through it, with a possibility to close off the open area inside the object.\
  The ground level is a glass cube with an art section and a canteen.\
  The library is a double-level space which looks outside the southern facade, with the administration offices being separated from the object and connected with a bridge.\
  The existing gymnasium has a new ceiling and extended height, with new equipment to support various activities.\
  To further accentuate the individuality of the gymnasium, it has been wrapped in white brick, whereas the other buildings are in concrete.",
};

export default language;
