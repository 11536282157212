import { useContext, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { LanguageContext } from "../contexts/Language";
import { t } from "../contexts/Language/reducer";

import { MEDIA_QUERY_MAX_WIDTH } from "../util/Constants";
import Image from "../util/Image";

const textNormalStyle = {
  textAlign: "left",
  fontSize: "20px",
};

const Text = styled(Typography, {})(({}) => textNormalStyle);

const RightText = styled(
  Typography,
  {}
)(({ extraStyle }) => ({
  ...extraStyle,
  textAlign: "right",
  fontSize: "30px",
}));

const TitleText = styled(
  Typography,
  {}
)(({}) => ({ ...textNormalStyle, fontWeight: "600" }));

export const AboutMe = (props) => {
  const [languageState, languageDispatch] = useContext(LanguageContext);

  const MAX_WIDTH_QUERY = `(max-width: ${MEDIA_QUERY_MAX_WIDTH}px`;
  const [matches, setMatches] = useState(
    window.matchMedia(MAX_WIDTH_QUERY).matches
  );

  useEffect(
    () =>
      window
        .matchMedia(MAX_WIDTH_QUERY)
        .addEventListener("change", (e) => setMatches(e.matches)),
    []
  );

  const renderSmall = () => (
    <Grid
      container
      xs={12}
      sx={{
        paddingLeft: "2%",
        marginTop: "10px",
        flex: "display",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        leftTextHolder
      >
        <Grid item sx={{ marginTop: "auto" }}>
          <TitleText>{t("education", languageState)}</TitleText>
          <Text>{`2011 - 2015 ${t("arh", languageState)}`}</Text>
          <Text>{t("ukim", languageState)}</Text>
          <Text>{`2011 - 2015 ${t("highschool", languageState)}`}</Text>
        </Grid>

        <Grid item sx={{ alignItems: "center", paddingTop: "2%" }}>
          <TitleText>{t("software", languageState)}</TitleText>
          <Grid flex="display" container xs={12}>
            <Grid container flex="display" direction="column" xs={6}>
              <Text>Autocad</Text>
              <Text>Sketch Up</Text>
              <Text>Illustrator</Text>
            </Grid>
            <Grid container flex="display" direction="column" xs={6}>
              <Text>Archicad</Text>
              <Text>Lumion</Text>
              <Text>Photoshop</Text>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ paddingTop: "2%" }}>
          <TitleText>{t("contact", languageState)}</TitleText>
          <Text>marija.stoilova09@gmail.com</Text>
        </Box>
      </Grid>

      <Grid item sx={{ paddingTop: "5%", paddingRight: "2%" }} xs={12}>
        <RightText extraStyle={{ fontWeight: "900" }}>
          {t("MarijaStoilova", languageState)}
        </RightText>
        <RightText>{t("Architect", languageState)}</RightText>
      </Grid>

      <Grid item placeholder xs={7}></Grid>

      <Grid sx={{ paddingRight: "2%" }} xs={5}>
        <Image filePath={"about-me-photo.jpg"} />
      </Grid>
    </Grid>
  );

  const renderNormal = () => (
    <Grid
      container
      xs={12}
      sx={{
        paddingLeft: "2%",
        paddingRight: "15px",
        paddingTop: "15px",
        flex: "display",
        flexDirection: "row",
      }}
    >
      <Grid
        item
        xs={4}
        sx={{
          height: "100%",
          alignItems: "flex-end",
          flex: "display",
          flexDirection: "column",
          marginTop: "auto",
        }}
      >
        <Box sx={{ alignItems: "flex-end", marginTop: "auto" }}>
          <TitleText>{t("experience", languageState)}</TitleText>
          <Text>{`${t("december", languageState)} 2021 - ${t(
            "april",
            languageState
          )} 2022 `}</Text>
          <Text>{`${t("parametar", languageState)}`}</Text>
        </Box>

        <Box sx={{ alignItems: "flex-end", paddingTop: "2%" }}>
          <TitleText>{t("education", languageState)}</TitleText>
          <Text>{`2011 - 2015 ${t("arh", languageState)}`}</Text>
          <Text>{t("ukim", languageState)}</Text>
          {/* <Text>{`2011 - 2015 ${t("highschool")}`}</Text> */}
        </Box>

        <Grid item sx={{ alignItems: "left", paddingTop: "2%" }}>
          <TitleText>{t("software", languageState)}</TitleText>
          <Grid flex="display" container xs={12}>
            <Grid container flex="display" direction="column" xs={6} left>
              <Text>Autocad</Text>
              <Text>Sketch Up</Text>
              <Text>Illustrator</Text>
            </Grid>
            <Grid container flex="display" direction="column" xs={6} right>
              <Text>Archicad</Text>
              <Text>Lumion</Text>
              <Text>Photoshop</Text>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ paddingTop: "2%" }}>
          <TitleText>{t("contact", languageState)}</TitleText>
          <Text>marija.stoilova09@gmail.com</Text>
        </Box>
      </Grid>

      <Grid item className="title" xs={4} sx={{ paddingRight: "2%" }}>
        <RightText extraStyle={{ fontWeight: "900" }}>
          {t("MarijaStoilova", languageState)}
        </RightText>
        <RightText>{t("Architect", languageState)}</RightText>
      </Grid>

      <Grid className="photo" xs={4}>
        <Image filePath={"about-me-photo.jpg"} />
      </Grid>
    </Grid>
  );

  return matches ? renderSmall() : renderNormal();
};
