export const reducer = (state, action) => {
  switch (action.type) {
    case "addProject": {
      return {
        ...state,
        projects: [...state.projects, action.payload.newProject],
      };
    }

    case "setCurrentProject": {
      return { ...state, currentProject: action.payload.project };
    }

    default:
      return state;
  }
};

export const initialState = {
  projects: [],
  currentProject: null,
};
