import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Button, Grid, Typography } from "@mui/material";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Image from "../../util/Image";

import { ProjectsContext } from "../../contexts/Projects";
import { setCurrentProject } from "../../contexts/Projects/actions";
import { LanguageContext } from "../../contexts/Language";
import { t } from "../../contexts/Language/reducer";

export const ProjectOverviewDialog = (props) => {
  const { project, open, setOpen } = props;
  const { imageSources, oneLiner } = project;

  const [projectState, projectDispatch] = useContext(ProjectsContext);
  const [languageState, languageDispatch] = useContext(LanguageContext);

  const navigate = useNavigate();

  const { title } = project;

  const handleClose = () => {
    setOpen(false);
  };

  const openProject = (path) => {
    setCurrentProject(projectDispatch, project);
    navigate(`/project`);
  };

  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography textAlign={"center"}>
            {t(title, languageState)}
          </Typography>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ paddingTop: "1%", flexGrow: 1, flexDirection: "row" }}
          >
            <Grid item xs={12} sx={{ paddingRight: "1%", paddingBottom: "2%" }}>
              <Typography justifySelf={"center"} textAlign={"center"}>
                {t(oneLiner, languageState)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Carousel interval={3000} autoPlay={true} infiniteLoop={true}>
                {imageSources
                  .filter((e) => e.type === "LANDSCAPE" && e.inOverview)
                  .map((e) => (
                    <Image filePath={e.path} />
                  ))}
              </Carousel>
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ paddingTop: "2%", width: "100%" }}
                onClick={openProject}
              >
                <Typography color="black">
                  {t("see-project", languageState)}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return renderDialog();
};
