export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#ffffff",
      contrastText: "#000000",
    },
    secondary: {
      main: "#b71c1c",
      contrastText: "#ffffff",
    },
    background: {
      default: "#fafafa",
      paper: "#fafafa",
    },
    info: {
      main: "#2196f3",
    },
  },
  typography: {
    fontFamily: "custom-font",
    fontWeightLight: 300,
  },
  shape: {
    borderRadius: 15,
  },
};
