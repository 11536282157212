import { Box } from "@mui/material";
import { ProjectsHighlights } from "../components/projectsHighlights/ProjectsHighlights";

export const Homepage = (props) => {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <ProjectsHighlights
        disableProjectRandomization={true}
        highlightedOnly={true}
        disableSortById={true}
      ></ProjectsHighlights>
    </Box>
  );
};
