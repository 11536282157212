import { Box } from "@mui/material";

import { ProjectsHighlights } from "../components/projectsHighlights/ProjectsHighlights";

export const Interior = (props) => {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <ProjectsHighlights
        projectCategoryFilters={["INTERIOR"]}
        disableProjectRandomization={true}
      />
    </Box>
  );
};
