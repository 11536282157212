import { useContext } from "react";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { ProjectsContext } from "../../contexts/Projects";
import { setCurrentProject } from "../../contexts/Projects/actions";

import { ProjectsHighlights } from "../projectsHighlights/ProjectsHighlights";

import { LanguageContext } from "../../contexts/Language";
import { t } from "../../contexts/Language/reducer";

import Image from "../../util/Image";

export const SingleProjectView = (props) => {
  const [projectsState, projectsDispatch] = useContext(ProjectsContext);
  const [languagesState, languagesDispatch] = useContext(LanguageContext);

  const { currentProject } = projectsState;

  if (!currentProject)
    return (
      <Box>
        <Typography>Please select a project to open:</Typography>
        <ProjectsHighlights
          onSafetyClick={setCurrentProject}
          dispatch={projectsDispatch}
        ></ProjectsHighlights>
      </Box>
    );

  const { title, commissionedFor, partner, descriptions, imageSources } =
    currentProject;

  const moreImages = imageSources.length > descriptions.length - 1;

  const renderDescription = (description) => (
    <Typography sx={{ margin: "6%" }}>
      {t(description, languagesState)}
    </Typography>
  );

  const renderImage = (imageSource) => (
    <Box sx={{ margin: "4%" }}>
      <Image alt={title} filePath={imageSource.path} />
    </Box>
  );

  const renderContents = () => {
    const components = [];

    const minLength = moreImages ? descriptions.length : imageSources.length;

    for (var i = 0; i < minLength; i++) {
      components.push(renderDescription(descriptions[i]));
      components.push(renderImage(imageSources[i]));
    }

    if (moreImages) {
      for (i = minLength; i < imageSources.length; i++)
        components.push(renderImage(imageSources[i]));
    } else {
      for (i = minLength; i < descriptions.length; i++)
        components.push(renderDescription(descriptions[i]));
    }

    return components;
  };

  return (
    <Box>
      <Box sx={{ paddingTop: "2%" }}>{t(title, languagesState)}</Box>
      <Box
        style={{
          color: "rgb(192, 192, 192)",
          "text-align": "center",
          "font-size": "1em",
          width: "100%",
        }}
      >
        {t(commissionedFor, languagesState)}
      </Box>
      {partner ? (
        <Box
          style={{
            color: "rgb(192, 192, 192)",
            "text-align": "center",
            "font-size": "0.8em",
          }}
        >
          {`${t("Partner", languagesState)}: ${t(partner, languagesState)}`}
        </Box>
      ) : null}
      <Box contents>{renderContents()}</Box>
    </Box>
  );
};
