import { useContext, useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { LanguageContext } from "../../contexts/Language";

import { ProjectOverviewDialog } from "../projects-main/ProjectOverviewDialog";

import { MEDIA_QUERY_MAX_WIDTH } from "../../util/Constants";
import Image from "../../util/Image";
import { t } from "../../contexts/Language/reducer";

export const ProjectHighlightSingle = (props) => {
  const { project, reversed, onSafetyClick, dispatch } = props;
  const { imageSources, title } = project;

  const [openProjectOverviewDialog, setOpenProjectOverviewDialog] =
    useState(false);

  const [languageState, languageDispatch] = useContext(LanguageContext);

  const MAX_WIDTH_QUERY = `(max-width: ${MEDIA_QUERY_MAX_WIDTH}px`;
  const [matches, setMatches] = useState(
    window.matchMedia(MAX_WIDTH_QUERY).matches
  );

  useEffect(
    () =>
      window
        .matchMedia(MAX_WIDTH_QUERY)
        .addEventListener("change", (e) => setMatches(e.matches)),
    []
  );

  var imageSource = imageSources.find((e) => e.toDisplay).path;

  const TextComponent = (props) => {
    const {
      project: { commissionedFor, title },
      styles,
    } = props;
    const { contentHolderStyle, titleStyle, subtitleStyle } = styles;

    return (
      <Grid item style={contentHolderStyle}>
        <Box style={titleStyle}>{t(title, languageState)}</Box>
        <Box style={subtitleStyle}>{t(commissionedFor, languageState)}</Box>
      </Grid>
    );
  };

  const SmallTextComponent = (props) => {
    const styles = {
      contentHolderStyle: {
        "align-self": "center",
        display: "block",
        "flex-direction": "column",
        justifyContent: "center",
        alignItems: "center",
      },
      titleStyle: {
        "text-align": "center",
        "padding-bottom": "2%",
        "font-size": "0.9em",
        width: "100%",
      },
      subtitleStyle: {
        color: "rgb(192, 192, 192)",
        "text-align": "center",
        "font-size": "0.5em",
        width: "100%",
      },
    };

    return <TextComponent project={props} styles={styles} />;
  };

  const RegularTextComponent = (props) => {
    const styles = {
      contentHolderStyle: {
        "align-self": "center",
        display: "block",
        "flex-direction": "column",
        "padding-left": "5%",
      },
      titleStyle: {
        "text-align": "center",
        "font-size": "2em",
        width: "100%",
      },
      subtitleStyle: {
        color: "rgb(192, 192, 192)",
        "text-align": "center",
        "font-size": "1.5em",
        width: "100%",
      },
    };

    return <TextComponent project={props} styles={styles} />;
  };

  const itemClicked = () => {
    if (onSafetyClick) {
      onSafetyClick(dispatch, project);
    } else {
      setOpenProjectOverviewDialog(true);
    }
  };

  const renderImage = () => {
    return (
      <Box minWidth="70%" onClick={itemClicked}>
        <Image alt={title} filePath={imageSource} />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        margin: "1%",
        "flex-direction": "row",
        "min-height": "100%",
      }}
    >
      <ProjectOverviewDialog
        open={openProjectOverviewDialog}
        setOpen={setOpenProjectOverviewDialog}
        project={project}
      ></ProjectOverviewDialog>

      {!reversed ? renderImage() : null}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        minWidth="25%"
        marginLeft="2%"
        display="flex"
        flex="1"
        minHeight="100%"
      >
        {matches ? (
          <SmallTextComponent {...project} />
        ) : (
          <RegularTextComponent {...project} />
        )}
      </Grid>

      {reversed ? renderImage() : null}
    </Box>
  );
};
