import * as React from "react";

import Grid from "@mui/material/Grid";

import { ProjectHighlightSingle } from "./ProjectHighlightSingle";
import { NUMBER_OF_PROJECTS_IN_HIGHLIGHTS } from "../../util/Constants";
import { randomSamples } from "../../util/Functions";

export const ProjectsHighlightsGrid = (props) => {
  const {
    onSafetyClick,
    dispatch,
    projects,
    disableProjectRandomization,
    highlightedOnly,
    disableSortById,
  } = props;

  const normalProjects = projects
    .sort((a, b) => (disableSortById ? 0 : a.id - b.id))
    .filter((e) => e.highlighted || !highlightedOnly);

  const displayProjects = disableProjectRandomization
    ? normalProjects
    : randomSamples(normalProjects, NUMBER_OF_PROJECTS_IN_HIGHLIGHTS);

  const projectItem = (project, reversed) => (
    <Grid
      item
      sx={{
        flexGrow: 1,
        flexDirection: "row",
        margin: "2%",
        alignItems: "center",
        justifyItems: "center",
        alignSelf: "center",
      }}
    >
      <ProjectHighlightSingle
        project={project}
        reversed={reversed}
        onSafetyClick={onSafetyClick}
        dispatch={dispatch}
      />
    </Grid>
  );

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      {displayProjects.map((e, idx) => projectItem(e, false))}
    </Grid>
  );
};
