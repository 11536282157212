import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

import ProjectCategoryOverlay from "./projects/ProjectCategoryOverlay";

import { LanguageContext } from "../../contexts/Language";
import { t } from "../../contexts/Language/reducer";

import { MEDIA_QUERY_MAX_WIDTH } from "../../util/Constants";
import { capitalizeWord } from "../../util/Functions";

export const StylesSmall = { typography: { fontSize: "16px" } };
export const StylesLarge = { typography: { fontSize: "30px" } };

const ScreenOverlay = (props) => {
  const { onItemSelect, onSubItemSelect, subOverlay, setSubOverlay } = props;

  const navigate = useNavigate();

  const goTo = (path) => {
    onItemSelect();
    navigate(`/${path}`);
  };

  const [languageState, languageDispatch] = useContext(LanguageContext);

  const MAX_WIDTH_QUERY = `(max-width: ${MEDIA_QUERY_MAX_WIDTH}px`;
  const [matches, setMatches] = useState(
    window.matchMedia(MAX_WIDTH_QUERY).matches
  );

  useEffect(
    () =>
      window
        .matchMedia(MAX_WIDTH_QUERY)
        .addEventListener("change", (e) => setMatches(e.matches)),
    []
  );

  const goToSubOverlay = (newSubOverlay) => {
    setSubOverlay(newSubOverlay);
  };

  const resetSubOverlaySelection = () => setSubOverlay(null);

  const styles = matches ? StylesSmall : StylesLarge;

  if (subOverlay === "projects") {
    return (
      <ProjectCategoryOverlay
        onItemSelect={onSubItemSelect}
        onBack={resetSubOverlaySelection}
        styles={styles}
        languageState={languageState}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Button variant="outline" onClick={() => goTo("")}>
        <Typography sx={{ ...styles.typography }}>
          {capitalizeWord(t("homepage", languageState))}{" "}
        </Typography>
      </Button>
      <Button variant="outline" onClick={() => goToSubOverlay("projects")}>
        <Typography sx={{ ...styles.typography }}>
          {capitalizeWord(t("projects", languageState))}
        </Typography>
      </Button>
      <Button variant="outline" onClick={() => goTo("about-me")}>
        <Typography sx={{ ...styles.typography }}>
          {capitalizeWord(t("about me", languageState))}
        </Typography>
      </Button>
    </Box>
  );
};

export default ScreenOverlay;
