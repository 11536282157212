import { Box } from "@mui/material";
import { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ScreenOverlay from "../screenOverlay/ScreenOverlay";

import TopNavigationBar from "../topNavigationBar/TopNavigationBar";

export default function AppContent() {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [subOverlay, setSubOverlay] = useState(null);
  const [isHamburgerClosed, setHamburgerClosed] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const toggleScreenOverlay = () => {
    setOverlayVisible(!overlayVisible);
    setHamburgerClosed(!isHamburgerClosed);
  };

  const overlayItemSelected = () => {
    toggleScreenOverlay();
    setHamburgerClosed(true);
  };

  const subOverlayItemSelected = () => {
    overlayItemSelected();
    setSubOverlay(null);
  };

  const inRootPath = () => ["", "/", "/home"].includes(location.pathname);

  const renderContent = () =>
    overlayVisible ? (
      <ScreenOverlay
        onItemSelect={overlayItemSelected}
        onSubItemSelect={subOverlayItemSelected}
        subOverlay={subOverlay}
        setSubOverlay={setSubOverlay}
      />
    ) : (
      <Outlet
        style={{
          paddingTop: "60px",
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      ></Outlet>
    );

  return (
    <Box
      sx={{
        width: "100%",
        // overflow: "hidden",
      }}
    >
      <TopNavigationBar
        onHamburgerClick={toggleScreenOverlay}
        isHamburgerClosed={isHamburgerClosed}
        backArrowVisible={subOverlay || !inRootPath()}
        goBackCallback={() =>
          subOverlay ? setSubOverlay(null) : navigate("/")
        }
      ></TopNavigationBar>
      <Box
        display={"flex"}
        minHeight="80%"
        justifyContent={"center"}
        alignItems={"center"}
        className="contentHolder"
      >
        {renderContent()}
      </Box>
    </Box>
  );
}
