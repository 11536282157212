import en from "../../internationalization/languages/en";
import mk from "../../internationalization/languages/mk";

export const reducer = (state, action) => {
  switch (action.type) {
    case "setLanguage": {
      return { ...state, language: action.payload.languageTag };
    }

    default:
      return state;
  }
};

export const initialState = {
  language: "en",
  languages: ["en", "mk"],
  languageContents: { en, mk },
};

const fallbackLanguage = "mk";

function getFallbackLanguageContents(languageContents) {
  return languageContents[fallbackLanguage];
}

function getFallbackLanguageTranslation(key, languageContents) {
  return getFallbackLanguageContents(languageContents)[key];
}

function translate(key, state) {
  const { language: currentLanguage, languages, languageContents } = state;

  if (!languages.includes(currentLanguage))
    return `Language ${currentLanguage} not supported.`;

  let translation =
    languageContents[currentLanguage][key] ||
    getFallbackLanguageTranslation(key, languageContents);

  if (!translation) return `Key ${key} not defined in language files!`;

  return translation;
}

export function t(key, state) {
  return translate(key, state);
}
