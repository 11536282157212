import { Box } from "@mui/system";
import { ProjectsHighlights } from "../components/projectsHighlights/ProjectsHighlights";

export const Student = () => {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <ProjectsHighlights
        projectCategoryFilters={["STUDENT"]}
        disableProjectRandomization={true}
      />
    </Box>
  );
};
