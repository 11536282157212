export const setLanguage = (dispatch, languageTag) => {
  dispatch({
    type: "setLanguage",
    payload: {
      languageTag,
    },
  });
};

export const setEnglish = (dispatch) => setLanguage(dispatch, "en");

export const setMacedonian = (dispatch) => setLanguage(dispatch, "mk");
