import { Box } from "@mui/system";
import { ProjectsHighlightsGrid } from "./ProjectsHighlightsGrid";
import data from "../../data/projects.json";

export const ProjectsHighlights = (props) => {
  const {
    onSafetyClick,
    dispatch,
    disableProjectRandomization,
    projectCategoryFilters,
    highlightedOnly,
    disableSortById,
  } = props;

  const { projects } = data;

  const filteredProjects =
    projectCategoryFilters && projectCategoryFilters.length
      ? projects.filter((e) => projectCategoryFilters.includes(e.category))
      : projects;

  return (
    <Box sx={{ overflow: "hidden" }}>
      {/* <Typography text="secondary">Highlights</Typography> */}
      <ProjectsHighlightsGrid
        onSafetyClick={onSafetyClick}
        dispatch={dispatch}
        projects={filteredProjects}
        disableProjectRandomization={disableProjectRandomization}
        highlightedOnly={highlightedOnly}
        disableSortById={disableSortById}
      />
    </Box>
  );
};
