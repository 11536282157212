const language = {
  test1: "вредност1",
  test2: "вредност2",
  projects: "проекти",
  "about me": "за мене",
  contact: "контакт",
  "My projects": "Проекти",
  "Show all": "Кон проекти",
  Partner: "Партнер",

  "see-project": "Кон проект",

  experience: "професионално искуство",
  december: "декември",
  april: "април",
  parametar: "параметар - Скопје",

  education: "образование",
  arh: "Архитектонски факултет",
  ukim: "Св. Кирил и Методиј",
  highschool: 'Гимназија "Јане Сандански" - Струмица',
  software: "софтвер",
  Architect: "Архитект",
  MarijaStoilova: "Марија Стоилова",
  email: "е-маил",

  homepage: "дома",
  studentprojects: "студентски",
  architecture: "архитектура",
  interiordesign: "внатрешен дизајн",
  creative: "креации",

  workinprogress: "наскоро",

  // project defs

  // INTERIOR DESIGN

  "project-irene-apartment": "Скулпторски стан",
  "project-irene-apartment-commissioned-for": "Битола",
  "project-irene-apartment-one-liner": "Двособен стан за млада скулпторка",

  // first
  "project-highschool-title": "Средно стручно училиште - Крушево",
  "project-highschool-commissioned-for": "Студио V - 2017 год.",
  "project-highschool-one-liner":
    "Овој проект е студентски проект за средно училиште од туристичко-угостителска струка.",
  "project-highschool-description-1":
    "Овој проект е студентски проект за средно училиште од туристичко-угостителска струка.",
  "project-highschool-description-2":
    "Програмата се развива хоризонтално и е составена од повеќе блокови во чијa што меѓусебна \
      поврзаност се испреплетуваат атриумски простори.",
  "project-highschool-description-3":
    "И покрај големата површина на која се\
      развива објектот, играта на волумени, нагласеноста на хоризонталата и високото зелениоло \
      околу објектот овозможуваат да тој биде човекомерен и ненаметлив во околината.",

  // second
  "project-domus-title": "Domus (лат.)",
  "project-domus-commissioned-for": "Студио VI - 2018 год.",
  "project-domus-partner": "Виолета Јованова",
  "project-domus-one-liner":
    "Domus е античко-римски атриумски тип на куќа каде животот се одвива околу внатрешниот двор.",
  "project-domus-description-1":
    "Domus е античко-римски атриумски тип на куќа каде животот се одвива околу внатрешниот двор. \
    Во класичниот распоред на римскиот домус, атриумот служел како фокус на целиот план на куќата. ",
  "project-domus-description-2":
    "Како главна просторија во јавниот дел од куќата атриумот бил центар на семејниот, \
    општествениот и политичкиот живот на куќата. \
    Таквиот тип на куќа послужи како инспирација за овој проект за градски куќи во низа со скалишни премини меѓу нив. \
    Секоја куќа во таа низа е домус, интровертен тип на куќа ориентирана кон внатрешен двор во кој е посадено дрво (стожар на домот). ",
  "project-domus-description-3":
    "Дневните простории се сместени во приземје опкружувајки го внатрешниот двор и поврзувајќи се во кружна врска, \
    отворањето на просториите кон дворот му даваат различни функции на тоа место, \
    претворајќи го во трепезарија работилница или дневна соба на отворено.",

  // third
  "project-botanicka-title": "Ботаничка градина",
  "project-botanicka-commissioned-for": "Студио VIII - 2019 год.",
  "project-botanicka-partner": "Симона Галазова",
  "project-botanicka-one-liner":
    "Суптилни ортогонални патеки, интегрирани во природа.",
  "project-botanicka-description-1":
    'Ботаничката градина при Природно математичкиот факултет е лоцирана на локација која граничи со Булеварот Александар Македонски, Хемискиот институт, Општинана Гази Баба и студентскиот дом "Стив Наумов".\
     На таа локација ние трасиравме ортогонални патеки, така што објектите се концентрирани само во еден дел од градината\
      и движењето останува непопречено за оние кои секојдневно минуваат тука.',
  "project-botanicka-description-2":
    "Ортогоналните патеки кои не водат низ градината се надополнети со ортогонално поставени стаклени кубуси со димензии MX3M и MX2M (М=6м)\
       со соодветни висини за растенијата кои се сместени во нив, високи 5 и 7 метри.\
        Сите тие се подземно поврзани со инсталации и имaат подземни регулатори на условите (температура и влажност на воздух).",
  "project-botanicka-description-3":
    'Шумата од дрвја од надворешниот дел од градината се пресликува во внатрешниот дел. \
    Конструктивно, стаклениците се носат на фасадни столбчиња кои ја носат фасадата \
    и "дрвја" - чадорест систен од кружен челичен столб и кружни коси заварени челични профили \
    кои ја формираат крошната на дрвото и го носат кровот.',

  // fourth
  "project-banja-title": "Бања Банско",
  "project-banja-commissioned-for": "Магистерски проект - 2021 год.",
  "project-banja-one-liner":
    "Интеграција на природата и архитектурата во хармонска целина.",
  "project-banja-description-1":
    "Овој труд е обид да се интегрираат природата и архитектурата така што ќе функционираат во хармониска целина, \
    а воедно и да се реанимира историско-културолошкиот дух на јавните бањи во современа форма.",
  "project-banja-description-2":
    "Одбраната локација се наоѓа на самиот раб на село Банско во туристичко-археолошката зона. \
    Конкретната локација на интервенција е тромеѓе на судир на поле-шума-арехеологија и архитектура, обединети, \
    влијанијата од сите сили кои влијаат на оваа конкретна локација резултираат да програмски и просторно се појави мегаструктура \
    која има кохерентен фактор на обединување во формалниот план создавајќи цврстина и непроменливост, \
    но е поимлива и делумно расчленета, поради тоа што поединечните елементи од таа целина поседуваат сопствен карактер. ",
  "project-banja-description-3":
    "Структурата лежи под густата шума на Беласица. \
    Бањата со својата поставеност е граница помеѓу шумата и населбата. \
    Поради својата положба, во рамките на објектот ќе се појавуваат празнини, исто како во полето, \
    исполнети со високо и ниско зеленило и пејзажи низ кои ќе протекува природата. \
    Поврзувајќи се идејата за “состојба на поле” од Стан Ален, објектот е преточен во поле а неговите елементи сраснуваат едни со други.",
  "project-banja-description-4":
    "Целиот комплекс е шума од дрвени или челични столбови во внатрешноста и шума од дрвени стебла во надворешноста. \
    Програмски објектот е составен од хотелско- ресторански дел и четирите бањи кои го сочинуваат бањскиот дел, секоја со различен карактер, функција и атмосфера. \
    Со промена на материјалноста заедно со присуството на празнини/градини во тоа поле, и комбинација на два архетипа, структурата постигнува идентификација и расчленување. \
    Додека пак со присуството на римскиот елемент лак, симболички го потсетуваме посетителот дека оваа бања изникнува од корените на старата римска бања, \
    но има свој споствен идентитет и карактер.",

  // fifth
  "project-skolo2020-title": "Парк во училиште",
  "project-skolo2020-commissioned-for": "Студио IX - 2020 год.",
  "project-skolo2020-one-liner":
    "Индивидуални единки единки од сличен карактер, споени во една целина.",
  "project-skolo2020-description-1":
    "Темата парк во училиште вклучува градинка и основно училиште со постоечка гимнастичка сала како два засебни објекти. \
    Локацијата е непосредно до градскиот парк и е значително мирна локација непосредно излегувајќи на еднонасочна улица и пешачка патека која води до паркот. \
    Обликовниот процес на проектот се потчинува на две референци, \
    едната е вклопување во околината па со својата ортогоналност и расчленетост двата објекти се надоврзуваат на постојното ткаење на соседството, \
    а втората е концепцептот за пропуштање на природата во објектите.",
  "project-skolo2020-description-2":
    "Градинката е организирана во два тракта меѓу кои се протега линиски двор/атриум видлив од сите делови на градинката \
    кој им овозможува на нејзините корисници поинакво просторно доживување. \
    Тој линиски двор има проширувања како дворови за секоја занимална, \
    додека на катот се наоѓаат јаслите и тие имаат тераси. \
    Ваквите дворови на занималните имаат систем на застаклување при што можат да се користат \
    и кога надвор условите не се погодни за игра на децата. \
    Остатокот од парцелата е партерно уреден со подлога за играње и зелени површини.",
  "project-skolo2020-description-3":
    "Училиштето функционира на сличен начин. \
    Објектот е процепан на средина и низ него протекува парк кој има можност да се затвори во објектот. \
    Приземјето е застаклен кубус со мулти уметничка содржина и трпезарија која навлегува во сутерен, \
    додека училниците се развиваат на катови нанижани на ходничкот систем со мултимедијални содржини долж ходниците и взаемно таквите трактови со училници се поврзани со мостови. \
    Библиотеката е двовисински простор кој излегува на јужната фасада, а администрацијата е издвоена од тој габарит и е поврзана со мост. \
    Постојната сала добива нова висина и дополнителни содржини, така што таа ја задржува својата независност и продолжува да егзистира независно од работата на училиштето. \
    Салата е обвиткана со бела тула додека за остатокот од градбите како материјал се користи бетон.",
};

export default language;
